$(document).on('turbolinks:load', function() {
  if (window.location.href.indexOf('enterprises/report_total') > -1) {
    partner_id = $('#payments_total_partner_select').val()
  if(partner_id != ''){
    $('#payments_total_client_select').removeAttr('disabled')
    populate_clients(partner_id).then(() =>{
      client_id = detect_client_id()
      if(client_id != ''){
        $('#client_option_' + client_id).attr('selected', 'true')
      }
    })
  } else {
    $('#payments_total_client_select').append("<option value id='default_client_option'>All Clients</option>")
  }
  $('#payments_total_partner_select').on('change', function(){
    partner_id = $('#payments_total_partner_select').val()
    if(partner_id != '') {
      $('#payments_total_client_select').removeAttr('disabled')
      populate_clients(partner_id)
    } else{
      $('#payments_total_client_select').attr('disabled', 'disabled')
      $('#default_client_option').attr('selected', 'true')
    }
  })
  }
})

async function populate_clients(partner_id) {
  await $.get('/enterprises/clients?partner_id=' + partner_id, function(clients){
    $('#payments_total_client_select').empty();
    $('#payments_total_client_select').append("<option value id='default_client_option'>All Clients</option>")
    for(var index in clients){
      $('#payments_total_client_select').append("<option value='" + clients[index].id + "'"+ "id='client_option_" + clients[index].id +"'>" + clients[index].name + "</option>")
    }
  })
}

function detect_client_id() {
  return new URLSearchParams(window.location.search).get('client_id')
}
