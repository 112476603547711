

'use strict';

function mount() {

  function showLoader(modal) {
    const bounce = '<div class="sk-double-bounce"><div class="sk-child"/><div class="sk-double-bounce1"/><div class="sk-double-bounce1"/></div>';
    $(modal).find('.modal-body').html(bounce)
  }

  $('#modal').on('show.bs.modal', function(e) {
    var params = $(e.relatedTarget)
    var path = params.data('path')
    var size = params.data('size') || "modal-lg"
    var modal = this;
    if (!path) return false;
    var modalDialog = $(modal).find('.modal-dialog');
    modalDialog.addClass(size);
    showLoader(modal);

    $.get(path, {}, function(html) {

    }).done(function(html) {

      $(modal).find('.modal-body').html(html);

    }).fail(function(xhr) {

      $(modal).find('.modal-body').html(xhr.responseText);

    }).always(function() {

      //hideLoader(modal);

    });
  });

  $('.modal').on('hidden.bs.modal', function(e) {
    var modal = this;
    // required otherwise remote links do not trigger flash message
    setTimeout(function() {
      $(modal).find('.modal-body').html('')
    }, 0);
  });

}

$(document).on('turbolinks:load', function() {
  mount();
})
