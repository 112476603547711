(function() {

  'use strict';

  (function($) {

    $.fn.moveUp = function() {
      var before = $(this).prev();
      $(this).insertBefore(before);
    }

    $.fn.moveDown = function() {
      var after = $(this).next();
      var id = after.get(0).id;
      if (id && id.startsWith('uuid')) {
        $(this).insertAfter(after);
      }
    }

  }(jQuery));


})();